import React, {useEffect} from 'react'
import Layout from '../layouts/Layout'
import I18n from '../utils/I18n'


const CodeOfConductTemplate = props => {
    const { pageContext: { lang, data, slug } } = props
    const i18n = new I18n({ lang })
    const contentEN = data.find(d => d.lang === 'en') || ''
    const contentTranslated = data.find(d => d.lang === lang) || null
    const content = contentTranslated || contentEN
    var intervalFix = null

    // Run only once using the second parameter as []
    useEffect(() => {
      customElements.whenDefined("tm-content").then(() => {
        const tmContentElement = document.querySelector('tm-content')
        
        intervalFix = setInterval(() => {
          const aside = tmContentElement.shadowRoot.querySelector('.sidebar-heading') || null
          
          if(aside) {
            tmContentElement.shadowRoot.querySelector('.sidebar-heading').innerHTML = i18n.text('coc_sidebar_title')
            clearInterval(intervalFix)
          }
        }, 100)
      })
    }, [])

    
    return (
      <Layout slug={slug} titleOverride="Community Code of Conduct | Salesforce Trailhead">
        <div key="dangerousHtml" dangerouslySetInnerHTML={{ __html: content.html }}/>
      </Layout>
    )
  }
  
  export default CodeOfConductTemplate